























































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import { CodeTFA } from '@/store/modules/profile/types'
import { getDateWithDifference } from '@/api/v3/DataGenerator'
import { loginStore } from '@/store'
import * as actionTypes from '@/store/actionTypes'
import type { AuthOption, AuthStep } from '@/store/modules/login/types'
import { changeServer } from '@/electron'
import type { Country } from '@tada-team/tdproto-ts'
import TheLoginHeader from './UI/TheLoginHeader.vue'
import TheLoginFooter from './UI/TheLoginFooter.vue'
import TheSubmitSection from './UI/TheSubmitSection.vue'
import TheMainInputSection from './TheMainInputSection/index.vue'
import type RecaptchaType from './Recaptcha/index.vue'
import { getDefaultServer, isOnCustomServer } from '@/utils'
import { loginLogger } from '@/loggers'
import {
  isRecaptchaEnabled,
  version as captchaVersion,
  v2,
  isRecaptchaEnabledV2,
} from '@/recaptcha'

@Component({
  components: {
    TheLoginTabs: () => import('./UI/TheLoginTabs.vue'),
    TheServerChangeStep: () => import('./steps/TheServerChangeStep.vue'),
    TheSecondFactorLoginStep: () =>
      import('./steps/TheSecondFactorLoginStep.vue'),
    TheSecondFactorDiscardStep: () =>
      import('./steps/TheSecondFactorDiscardStep.vue'),
    TheSmsStep: () => import('./steps/TheSmsStep.vue'),
    TheLoginHeader,
    TheLoginFooter,
    TheSubmitSection,
    TheMainInputSection,
    Recaptcha: () => import('./Recaptcha/index.vue'),
  },
})
export default class LoginIndex extends Vue {
  @Ref() readonly recaptcha!: RecaptchaType | undefined
  // SMS and Phone data
  phoneNumber = ''
  smsCode = ''
  smsCodeLength = 4
  selectedCountry: Country | null = null

  // Login/Pass data
  login = ''
  password = ''

  // TERMS
  acceptTerms = false
  termsErr = false

  // ELECTRON
  server = ''
  serverInput = ''
  isCustomBuild = false

  // AUTH CONTROLLER
  currentAuthOption: AuthOption = 'bySms'
  authStep: AuthStep = 'Phone'
  prevStep: AuthStep = 'Phone'

  // STATE
  err = ''
  smsCodeErr = ''
  loading = false

  codeTimeout = -1
  codeInterval: number | null = null

  // 2FA STATE
  secondFactorToken = ''
  secondFactorRecovery = false

  secondFactorHint = ''
  secondFactorDiscardCodeLength = 4
  secondFactorEmailMask = ''
  secondFactorErr = ''
  forgotPasswordLoading = false

  secondFactorPass = ''
  secondFactorDiscardCode = ''

  // oauthUrl = '' // todo

  // recaptcha
  isRecaptchaEnabledV2 = isRecaptchaEnabledV2

  recaptchaToken = ''

  async created () {
    if (this.$q.platform.is.mobile && !loginStore.state.seenMobile) {
      this.$router.push({ name: 'Mobile' })
      return
    }

    loginStore.actions.setupAuthOptions()
    this.currentAuthOption = loginStore.state.currentAuthOption

    this.selectedCountry = loginStore.state.countries[0]
    this.phoneNumber = this.selectedCountry.code

    if (this.$q.platform.is.electron) {
      this.isCustomBuild = await loginStore.actions.setupElectronServerChanger(
        buffer => {
          this.phoneNumber = buffer.phone
          this.server = buffer.server
        },
      )
      if (isOnCustomServer()) {
        this.server = window.location.origin
      }
    }
  }

  get recaptchaOptionsEnabledV2 (): boolean {
    return this.isAuthOptionPhone && this.isRecaptchaEnabledV2
  }

  get bgStyle () {
    return loginStore.getters.backgroundStyles
  }

  get title (): string {
    if (this.isChangeServerStep) { return this.$t('auth.newLogin.serverTitle').toString() }
    if (this.isSecondFactorDiscardStep) { return this.$t('auth.newLogin.discardPassTitle').toString() }
    if (this.isSecondFactorStep) { return this.$t('auth.newLogin.secondTitle').toString() }
    if (this.isSmsStep) return this.phoneNumber
    return this.$t('auth.newLogin.authTitle').toString()
  }

  get submitBtnText (): string {
    // 2fa: Начать сначала / Вернуться к вводу пароля
    if (this.isChangeServerStep) { return this.$t('auth.newLogin.connect').toString() }
    if (this.isPhoneStep && this.isAuthOptionPhone) { return this.$t('auth.newLogin.getCode').toString() }
    if (this.currentAuthOption === 'byPassword') { return this.$t('auth.twoFactorAuth.continue').toString() }
    if (this.isSecondFactorDiscardStep) return this.$t('common.next').toString()
    return this.$t('auth.enterByPasswordButton').toString()
  }

  get changeServerText (): string {
    return this.isChangeServerStep || this.server
      ? this.$t('auth.newLogin.usualServer').toString()
      : this.$t('auth.newLogin.corpServer').toString()
  }

  // todo mapper with norm code)
  get authTabs () {
    if (!this.isPasswordStep && !this.isPhoneStep) return []
    const tabs = loginStore.state.authOptions.map(opt => {
      return {
        value: opt,
        label:
          opt === 'bySms'
            ? this.$t('modals.UserProfile.phone')
            : this.$t('auth.newLogin.login'),
      }
    })
    return tabs
  }

  get isAuthOptionPhone () {
    return this.currentAuthOption === 'bySms'
  }

  get isChangeServerStep () {
    return this.authStep === 'ChangeServerStep'
  }

  get isPhoneStep () {
    return this.authStep === 'Phone'
  }

  get isSmsStep () {
    return this.authStep === 'SmsCode'
  }

  get isPasswordStep () {
    return this.authStep === 'Password'
  }

  get isSecondFactorStep () {
    return this.authStep === 'SecondFactor'
  }

  get isSecondFactorDiscardStep () {
    return this.authStep === 'DiscardSecondFactor'
  }

  get isSingleAuthOption () {
    return loginStore.state.authOptions.length === 1
  }

  get isCustomServer () {
    return window.FEATURES.custom_server
  }

  get showServerDispose () {
    return (
      this.isChangeServerStep ||
      (this.isMainStep && !this.isCustomBuild && !this.isCustomServer)
    )
  }

  get isMainStep () {
    return this.isPhoneStep || this.isPasswordStep
  }

  get serverLabel () {
    return this.isChangeServerStep || this.isMainStep ? this.server : ''
  }

  get onSubmitAction () {
    if (this.isChangeServerStep) return this.serverChangeAction
    if (this.isSecondFactorDiscardStep) return this.tryDiscardSecondFactor
    if (this.isSecondFactorStep) return this.tryLoginBySecondFactor
    if (this.isAuthOptionPhone) return this.onPhoneInput
    if (this.currentAuthOption === 'byPassword') return this.tryLoginByPassword
  }

  /**
   * Be careful with changes there
   * @see ThePhoneInputForm.vue -> onInputCountry()
   */
  async countryToDefault () {
    const code = this.selectedCountry?.code ?? ''
    const phone = this.phoneNumber.replace(/\s/g, '').replace(code, '')
    this.selectedCountry = await loginStore.actions.getCountries()
    this.phoneNumber = this.selectedCountry?.code + phone
  }

  serverChangeAction () {
    this.checkServerExists(this.serverInput)
  }

  /**
   * Try to setup new electron server
   * @todo refactor and move to electron utils
   */
  async checkServerExists (server: string) {
    const prefix =
      server.startsWith('http:') || server.startsWith('https:')
        ? ''
        : 'https://'
    const newServer = prefix + server
    if (newServer.startsWith(window.location.origin)) {
      this.err = this.$t('auth.sameServerError').toString()
      return
    }

    this.loading = true

    // define this fn
    // JSONP will call in with it with contents of 'features.json'
    window.checkServerExists = (features: any) => {
      if (features) {
        const buffer = { phone: this.phoneNumber, server: newServer }
        changeServer(buffer)
      } else {
        // should never get to this point
        // (see 'script.onerror' that will fire first)
        this.err = this.$t('auth.newServerError').toString()
      }
      this.loading = false
    }
    // construct URL to retrive JSONP with
    const fileURL = newServer + '/features.js?jsonp=checkServerExists'
    // create script element with this URL
    const script = document.createElement('script')
    script.src = fileURL
    // provide 'onerror' callback that will fire,
    // if features JSONP does not exist on server
    script.onerror = () => {
      this.err = this.$t('auth.newServerError').toString()
      this.loading = false
    }
    // append the script to head, where it will immediately start loading
    const head = document.querySelector('head')
    head && head.appendChild(script)
  }

  disposeErrors () {
    this.err = ''
    this.termsErr = false
    this.smsCodeErr = ''
  }

  onHeaderBackEvent () {
    this.isChangeServerStep
      ? this.setStep(this.prevStep)
      : this.setStep('ChangeServerStep')
  }

  setStep (step: AuthStep) {
    this.prevStep = this.authStep
    this.authStep = step
    this.disposeErrors()
  }

  changeServerClick () {
    if (this.server) {
      this.checkServerExists(getDefaultServer())
      return
    }
    this.authStep === 'ChangeServerStep'
      ? this.setStep(this.prevStep)
      : this.setStep('ChangeServerStep')
  }

  onDiscardCodeInput (v: string) {
    this.secondFactorDiscardCode = v
    this.secondFactorErr = ''
  }

  async tryDiscardSecondFactor () {
    if (!this.secondFactorDiscardCode) {
      this.secondFactorErr = this.$t('auth.newLogin.noCode').toString()
      return
    }
    this.loading = true

    try {
      await this.$store.dispatch(actionTypes.TFA_DISCARD_PASS_LOGIN, {
        token: this.secondFactorToken,
        code: this.secondFactorDiscardCode,
      })
      /**
       * After succesfull discarding send SMS again and go to input it for login
       */
      await this.onPhoneInput()
      this.smsCode = ''
      this.setStep('SmsCode')

      window.goal('loginAction', {
        login: '2fa: Успешный сброс 2фа-пароля',
      })
    } catch (e) {
      this.secondFactorErr = e.details?.code ?? e.error
      window.goal('loginAction', {
        login: '2fa: НЕуспешный сброс 2фа-пароля',
      })
    }
    this.loading = false
  }

  async tryLoginBySecondFactor () {
    if (!this.secondFactorPass.trim()) {
      this.secondFactorErr = this.$t('auth.newLogin.noPassword').toString()
      return
    }

    this.secondFactorErr = ''
    this.loading = true

    try {
      const result = await loginStore.actions.loginBySecondFactor({
        token: this.secondFactorToken,
        password: this.secondFactorPass,
      })
      loginStore.actions.loginIntoApp({ result, phone: this.phoneNumber })
      window.goal('loginAction', { login: '2fa: Успешный вход' })
    } catch (e) {
      this.secondFactorHint = e.details?.hint || ''
      this.secondFactorErr = e.details?.password ?? e.error
      this.loading = false
      window.goal('loginAction', { login: '2fa: НЕуспешный вход' })
    }
  }

  async tryLoginByPassword () {
    const isLoginEmpty = !this.login.trim()
    const isPasswordEmpty = !this.password.trim()
    if (isLoginEmpty && isPasswordEmpty) {
      this.err = this.$t('auth.newLogin.noLoginAndPassword').toString()
      return
    } else if (isLoginEmpty) {
      this.err = this.$t('auth.newLogin.noLogin').toString()
      return
    } else if (isPasswordEmpty) {
      this.err = this.$t('auth.newLogin.noPassword').toString()
      return
    }

    if (!this.acceptTerms) {
      this.termsErr = true
      return
    }

    this.loading = true

    try {
      const result = await loginStore.actions.authByPassword({
        login: this.login,
        password: this.password,
      })

      loginStore.actions.loginIntoApp({ result, phone: this.phoneNumber })
    } catch (e) {
      this.err =
        (e.details?.username || e.details?.password || e.details?.['']) ??
        e.error
      this.loading = false
    }
  }

  async onPhoneInput () {
    // because mask of quasar input doesn't correct drop by getter
    const phone = this.phoneNumber.split(' ').join('')

    if (phone === this.selectedCountry?.code) {
      this.err = this.$t('auth.newLogin.noPhone').toString()
      return
    }

    /**
     * Early exit here if this method was triggered by country input event.
     * (Some issue with global handler on q-page, needs exploring)
     */
    if (loginStore.state.countries.some(country => phone === country.code)) {
      return
    }

    window.goal('loginAction', { login: 'SMS: кнопка "Получить код"' })

    if (!this.acceptTerms) {
      this.termsErr = true
      return
    }

    this.loading = true
    try {
      let token: string | undefined

      if (!isRecaptchaEnabled) {
        loginLogger.log('Recaptcha is not enabled, skipping step...')
      } else {
        token = captchaVersion === v2
          ? this.recaptchaToken
          : await this.getCaptchaTokenV3()
      }

      const result = await loginStore.actions.loginByPhone({
        phone: this.phoneNumber,
        token,
      })

      if (isRecaptchaEnabled && captchaVersion === v2) {
        this.resetCaptchaV2()
      }

      this.smsCodeLength = result.codeLen
      this.setStep('SmsCode')
      this.setNextCodeTimeout(result.nextCodeAtDate)
    } catch (e) {
      window.goal('loginAction', { login: 'Login: ошибка запроса СМС' })
      this.err = e.details?.phone || e.details?.token || e.details[''] || e.error
    }

    this.loading = false
  }

  resetCaptchaV2 (): void {
    this.recaptcha && this.recaptcha.reset()
  }

  async getCaptchaTokenV3 (): Promise<string | undefined> {
    /**
     * This shouldn't ever throw, but just in case...
     */
    try {
      loginLogger.debug('Awaiting recaptcha load...')
      await this.$recaptchaLoaded()
    } catch (e) {
      const errText = this.$t('auth.captcha.errors.loading').toString()
      loginLogger.error(errText, e)
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ error: errText })
    }

    try {
      loginLogger.debug('Obtaining recaptcha token...')
      const token = await this.$recaptcha('sms')
      loginLogger.log('Obtained recaptcha token:', token)
      return token
    } catch (e) {
      const errText = this.$t('auth.captcha.errors.loading').toString()
      loginLogger.error(errText, e)
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ error: errText })
    }
  }

  async onSmsCodeInput (value: string) {
    this.smsCodeErr = ''
    this.smsCode = value
    this.loading = true

    try {
      const result = await loginStore.actions.cookieAuth({
        smsCode: this.smsCode,
        phoneNumber: this.phoneNumber,
      })

      window.goal('loginAction', { login: 'SMS: Успешный логин по СМС' })

      if (result.required2fa) {
        if (result.method2fa === 'password') {
          this.secondFactorToken = result.token
          this.secondFactorRecovery = result.recovery2fa ?? false
          this.setStep('SecondFactor')
          this.loading = false
        } else {
          this.smsCodeErr = this.$t(
            'auth.newLogin.unknownSecondFactor',
          ).toString()
        }
      } else {
        loginStore.actions.loginIntoApp({ result, phone: this.phoneNumber })
      }
    } catch (e) {
      window.goal('loginAction', { login: 'SMS: Неуспешный логин по СМС' })
      this.smsCodeErr = e.details?.code || e.details?.[''] || e.error
      this.loading = false
    }
  }

  async onForgotPasswordClick () {
    this.forgotPasswordLoading = true
    this.secondFactorErr = ''

    try {
      const r: CodeTFA = await this.$store.dispatch(
        actionTypes.TFA_SEND_DISCARD_CODE_LOGIN,
        this.secondFactorToken,
      )
      this.secondFactorDiscardCodeLength = r.codeLength
      this.secondFactorEmailMask = r.email || ''

      this.setStep('DiscardSecondFactor')
      this.setNextCodeTimeout(r.nextCodeAt)
    } catch (e) {
      this.err = e.details?.email ?? e.error
      this.secondFactorErr = e.details?.email ?? e.error
    }
    this.forgotPasswordLoading = false
  }

  setNextCodeTimeout (nextCodeAt: Date) {
    this.codeTimeout =
      (new Date(nextCodeAt).getTime() - getDateWithDifference('ts')) / 1000

    this.codeInterval =
      this.codeInterval ||
      setInterval(() => {
        if (this.codeTimeout >= 0) {
          this.codeTimeout--
        } else {
          this.codeInterval && clearInterval(this.codeInterval)
          this.codeInterval = null
        }
      }, 1000)
  }
}

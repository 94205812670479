

























import { getDefaultServer, isOnCustomServer } from '@/utils'
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheLoginHeader',
  components: {
    ArrowLeftIcon: () =>
      import('@/components/UI/icons/LoginBadIcons/IconArrowLeftNew.vue'),
  },
})
export default class TheLoginHeader extends Vue {
  @Prop({ type: String, required: true }) readonly title!: string
  @Prop({ type: String, default: '' }) readonly server!: string
  @Prop({ type: Boolean, default: false }) readonly showServerDispose!: boolean

  get serverLabel () {
    if (!this.$q.platform.is.electron) return ''
    return isOnCustomServer() ? this.server : getDefaultServer()
  }

  get showBackIcon () {
    return this.showServerDispose && this.server
  }
}

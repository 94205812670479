










































import { QInput } from 'quasar'
import { Component, Vue, Prop, Ref } from 'vue-property-decorator'

@Component({
  components: {
    IconClosedEye: () => import('@/components/UI/icons/IconClosedEye.vue'),
    IconOpenedEye: () => import('@/components/UI/icons/IconOpenedEye.vue'),
    IconClearInput: () => import('@/components/UI/icons/IconClearInput.vue'),
  },
})
export default class BaseInput extends Vue {
  @Prop({ type: String, required: true }) readonly value!: string
  @Prop({ type: String, required: true }) readonly placeholder!: string
  @Prop({ type: Boolean, default: false }) readonly isError!: boolean
  @Prop({ type: String, default: 'text' }) readonly type!: 'text' | 'password' | 'tel'
  @Prop({ type: Boolean, default: true }) readonly autofocus!: boolean
  @Prop({ type: Boolean, default: false }) readonly clearable!: boolean
  @Prop({ type: String, default: '' }) readonly mask!: string
  @Prop({ type: Number, default: 200 }) readonly maxlength!: number
  @Prop({ type: Boolean, default: false }) readonly counter!: boolean

  @Ref()
  readonly input!: QInput | undefined

  showPass = false

  get inputType (): 'text' | 'password' | 'tel' {
    if (this.type === 'tel') return 'tel'
    return this.type === 'text' || this.showPass ? 'text' : 'password'
  }

  hidePass () {
    this.showPass = false
    this.focus()
  }

  public focus () {
    this.input && this.input.focus()
  }
}

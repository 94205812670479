


























import { Component, Vue, Prop } from 'vue-property-decorator'
import TheCountryPicker from './TheCountryPicker.vue'
import BaseInput from '@/components/Modals/Default/TwoFactorAuth/components/BaseInput.vue'
import { loginStore } from '@/store'

import type { Country } from '@tada-team/tdproto-ts'

@Component({
  name: 'ThePhoneInputForm',
  components: {
    BaseInput,
    TheCountryPicker,
  },
})
export default class ThePhoneInputForm extends Vue {
  @Prop({ type: Boolean, default: false }) readonly showLabel!: boolean
  @Prop({ type: String, default: '' }) readonly error!: string
  // todo check default = null
  @Prop({ required: true }) readonly country!: Country | null
  @Prop({ type: String, required: true }) readonly phone!: string

  mask = this.getMask()

  async created () {
    if (loginStore.state.countries.length === 1) {
      const defaultCountry: Country = await loginStore.actions.getCountries()
      this.onInputCountry(defaultCountry)
    }
  }

  /**
   * Be careful with changes there
   * Need removing all spaces becase of Quasar mask
   * @see mask
   * cause it's works not correct and as async for next tick (next country pick i think)
   */
  async onInputCountry (value: Country | null) {
    // eslint-disable-next-line no-useless-escape
    let phone = this.phone.replace(/\s|\-/g, '')

    const slice = phone.slice(0, this.country?.code.length ?? 0)

    phone = phone.replace(slice, value?.code ?? '')

    window.goal('loginAction', { login: 'Login: Изменена страна' })

    this.mask = this.getMask(value)

    await this.$nextTick()

    this.onInputPhone(phone)
    this.$emit('update:country', value)
  }

  onInputPhone (value: string) {
    if (!value.length) this.$emit('update:country', null)
    this.$emit('update:phone', value)
    this.$emit('update:error', '')
  }

  private getMask (country = this.country): string {
    return country?.code === '+7' ? '+# ### ###-##-##' : ''
  }
}





















































import { Component, Vue, Prop } from 'vue-property-decorator'

import type { Country } from '@tada-team/tdproto-ts'
import { loginStore } from '@/store'

@Component({
  name: 'TheCountryPicker',
  components: {
    IconChevronBottom: () =>
      import('@/components/UI/icons/LoginBadIcons/IconChevronBottomNew.vue'),
  },
})
export default class TheCountryPicker extends Vue {
  @Prop({ required: true }) readonly value!: Country | null

  isPopupShown = false

  get countries (): Array<Country> {
    return loginStore.state.countries
  }

  get label (): string {
    return this.value?.name ?? this.$t('auth.newLogin.chooseCountry').toString()
  }

  get classString (): string {
    return this.value ? 'text-td-black-1' : 'text-td-gray-5'
  }

  changePopupState (value: boolean) {
    this.isPopupShown = value
  }
}













































import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheAcceptTermsSection',
})
export default class TheAcceptTermsSection extends Vue {
  @Prop({ type: Boolean, default: false }) readonly error!: boolean
  @Prop({ type: Boolean, required: true }) readonly value!: boolean

  onTermsClick () {
    window.goal('loginAction', {
      login: 'Login: Клик на пользовательское соглашение',
    })
  }

  onPoliticClick () {
    window.goal('loginAction', {
      login: 'Login: Клик на политику конфиденциальности',
    })
  }
}

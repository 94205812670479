























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TheSubmitAction',
})
export default class TheSubmitAction extends Vue {
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean
  @Prop({ type: String, default: '' }) readonly text!: string
  @Prop({ type: Boolean, default: false }) readonly disable!: boolean
}






































import { Component, Vue, Prop } from 'vue-property-decorator'

import ThePhoneInputForm from './ThePhoneInputForm.vue'
import TheAcceptTermsSection from './TheAcceptTermsSection.vue'

import type { Country } from '@tada-team/tdproto-ts'

@Component({
  name: 'TheMainInputSection',
  components: {
    TheLoginAuthForm: () => import('./TheLoginAuthForm.vue'),
    TheAcceptTermsSection,
    ThePhoneInputForm,
  },
})
export default class TheMainInputSection extends Vue {
  @Prop({ type: Boolean, default: true }) readonly isAuthOptionPhone!: boolean
  @Prop({ type: Boolean, default: true }) readonly showPhoneLabel!: boolean
  @Prop({ type: String, default: '' }) readonly error!: string
  @Prop({ type: Boolean, default: false }) readonly termsError!: boolean

  @Prop({ type: Boolean, required: true }) readonly acceptTerms!: boolean

  @Prop({ type: String, required: true }) readonly phone!: string
  @Prop({ required: true }) readonly country!: Country | null

  @Prop({ type: String, required: true }) readonly login!: string
  @Prop({ type: String, required: true }) readonly password!: string
}












































import { Component, Vue, Prop } from 'vue-property-decorator'
import { loginStore } from '@/store'
import i18n from '@/i18n'

import { fasGlobe } from '@quasar/extras/fontawesome-v5'

@Component({
  name: 'TheLoginFooter',
})
export default class TheLoginFooter extends Vue {
  @Prop({ type: Boolean, default: false }) readonly isCustomBuild!: boolean
  @Prop({ type: String, default: '' }) readonly serverText!: string

  language = 'ru'

  icon!: string

  created () {
    this.icon = fasGlobe
    this.language = i18n.locale ?? 'ru'
  }

  get faqHref () {
    const base = 'https://tada.team/faq-'
    return base + i18n.locale
  }

  get changeLanguageLabel () {
    return this.language === 'ru' ? 'English' : 'Русский'
  }

  async changeLanguage () {
    this.language = await loginStore.actions.changeLang()
    this.$emit('changeLang')
  }

  onFaqClick () {
    window.goal('loginAction', { login: 'Footer: Клик на "Вопрос-ответ"' })
  }

  onChangeServerClick () {
    this.$emit('changeServer')
    window.goal('loginAction', { login: 'Footer: Клик по кнопке "Корпоративный серввер"' })
  }
}
